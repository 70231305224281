<template>
    <div class="myCleaning">
<!--        <van-nav-bar title="我的保洁" @click-left="leftReturn" left-arrow></van-nav-bar>-->
        <rxNavBar title="我的保洁" androidOrIOSFlag="true"></rxNavBar>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="cleaning">
<!--            轮播图 autoplay:自动播放时间 bannerList:轮播数组 swiperJump:跳转富文本或者其他页面方法  height:滑块高度 indicators:是否有指示器-->
            <dial-swiper :autoplay="autoplay" :bannerList="bannerList" :indicators="indicators" :height="height" @swiperJump="swiperJump(arguments)" >
            </dial-swiper>

            <div class="" @click="iWantCleaning">
                <img class="clenaing_img" src="../../../assets/images/applicationClenaing2x.png"/>
                <div class="applicationClenaing">申请保洁</div>
                <div class="applicationMessage">线上预约保洁，快速派单做清洁</div>
            </div>
        </div>
        <img class="applicationClenaing_case" src="../../../assets/images/head-quotation-mark.png"/>
        <div class="myClenaing_text">我的保洁</div>
        <div class="cleaning" v-if="noContent"> <common-empty ></common-empty></div>
        <div class="cleaning" v-for="item in cleanUserList" :key="item.id" v-else>

            <!--待处理-->
            <div class="complaint_message">
                <div :class="item.afterRentStatus=='派单中'?'wait-approveA':item.afterRentStatus=='待审批'||item.afterRentStatus=='待处理'?'wait-approve':item.afterRentStatus=='已验收'||item.afterRentStatus=='已完成'||item.afterRentStatus=='已取消'?'wait-approveB':'wait-approve'">
                    {{item.afterRentStatus}}
                </div>
                <div class="comlaint_site">
                    <div class="site">{{item.address}}</div>
                </div>
                <div  class="houseInfo">
                   <div class="boldFont" >预约时间：<span class="normalFont">{{item.appointmentTime}}</span></div>
                    <div class="boldFont" :class="item.afterRentStatus!='已完成'&&item.afterRentStatus!='已验收'? 'trashCanHide':''">保洁时间：<span class="normalFont">{{item.finishTime}}</span></div>
                   <div class="boldFont" v-for="(type,id) in cleanType" :key="id" v-show="item.cleanType==type.id">保洁类型：<span class="normalFont" >{{type.dictionaryTitle==''||type.dictionaryTitle==undefined?'无':type.dictionaryTitle}}</span></div>
                    <div class="boldFont" v-for="items in cosbearMap " v-if="item.afterRentStatus=='派单中'&&item.costBear==items.dictionaryValue" >{{items.dictionaryTitle}}：<span class="normalFont" style="color: #FF6443">{{item.expectFee==''||item.expectFee==undefined? '0.00':item.expectFee}}元</span></div>
                   <div class="boldFont" v-for="items in cosbearMap "  v-if="item.afterRentStatus!='派单中'&&item.costBear==items.dictionaryValue">{{items.dictionaryTitle}}：<span class="normalFont" style="color: #FF6443">{{item.lastFee==''||item.lastFee==undefined? '0.00':item.lastFee}}元</span></div>
                </div>
                <div :class="item.afterRentStatus!='待审批'&&item.afterRentStatus!='待处理'? 'trashCanHide':''" @click="cancelClick(item.afterRent_id)" class="primaryClear">
                    <button class="button" type="primary">取消保洁</button>
                </div>
                <div class="single" :class="item.afterRentStatus !='派单中' ? 'trashCanHide':''">
                    <img class="single_people" src="../../../assets/images/cleanPeople2x.png"/>
                    <div class="single_aunt">{{item.userName}}<span class="single_phone">{{item.mobile}}</span></div>
                    <img class="single_img" src="../../../assets/images/tel.png" @click="showMobile(item.mobile,item.userName)" />
                </div>
            </div>
            <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName" :dialogTitle="dialogTitle" :mobileShow="isMobileShow" @hideDialog="hideDialog">
            </dial-mobile>
        </div>
        </van-pull-refresh>
    </div>
</template>

<script>
    import { NavBar,Icon,Button,NumberKeyboard,Swipe,SwipeItem,PullRefresh} from 'vant';
    import {querycleaningList,getQueryBaseData,userAfterRentCancel} from "../../../getData/getData";
    import {globaluserId, responseUtil,checkIOS,checkAndroid} from "../../../libs/rongxunUtil";
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
    import dialSwiper from '../../../components/rongxun/rx-swiper/dialSwiper';
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import Vue from 'vue';

    /********调用APP方法 begin*********/
    function codeJump(){
        window.himi.codeJump();
    }
    /********调用APP方法 end*********/


    Vue.use(Swipe,SwipeItem,PullRefresh);
    export default {
        components: {
            [NavBar .name]:NavBar,
            [Swipe .name]:Swipe,
            [SwipeItem .name]:SwipeItem,
            [PullRefresh .name]:PullRefresh,
            [Icon .name]:Icon,
            [Button .name]:Button,
            [NumberKeyboard .name]:NumberKeyboard,
            dialSwiper,
            dialMobile,
            rxNavBar
        },
        data() {
            return {
                checkStatus:{
                    0:'待处理',
                    1:'派单中',
                    2:'已完成',
                },
                cleaningList:[
                    {
                        checkStatus : 0,
                        roomDetailedAddress:'家和花样年华一期-职工街100号1单元101号',
                        appointmentTime:'2020-03-20  13:30',
                        cleanType:'客厅  |  月保洁，双周保洁',
                        fee:'230.00'
                    },
                    {
                        checkStatus : 1,
                        roomDetailedAddress:'家和花样年华一期-职工街100号1单元101号  长长的',
                        appointmentTime:'2020-03-20  13:30',
                        cleanType:'公共区域  |  月保洁',
                        fee:'230.00',
                        cleaner_id:'张阿姨',
                        mobile:'17640399999',
                    },
                    {
                        checkStatus : 2,
                        roomDetailedAddress:'家和花样年华一期-职工街100号1单元101号  长长的',
                        appointmentTime:'2020-03-20  13:30',
                        cleanType:'公共区域  |  月保洁',
                        fee:'230.00',
                        cleaner_id:'张阿姨',
                        mobile:'17640399999',
                        cleanTime:'2020-03-20  13:30',
                    },
                ],
                isMobileShow:false,
                temporaryOwnerMobile:'',
                temporaryOwnerName:'',
                dialogTitle:'联系维修师傅',
                msg:"要跳转的页面",
                repairMobile:'',
                cleanUserLis:'',
                bannerList:[],
                cleanUserList:'',
                cleanType:'',
                cosbearMap:'',
                noContent:false,
                isLoading:false,
                afterRent_id:'',//租后ID
                type:'4',//租后取消类型
                autoplay:'2000',//自动播放
                height:"140",//滑块高度
                indicators:true,//是否需要指示器
                changeType:'2',
                dbName:["cleanType"],
                fdName:["CHECKSTATUSMAP","COSBEARMAP"],
                errorImg:[{"bannerPath":"https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png"}],//默认图片
            }
        },
        name: "myCleaning",
        mounted() {
            this.querycleaningList();
            this.getQueryBaseData();
        },
        methods:{
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },
            shows(){
                this.show=true;
            },
            save(){

            },
            iWantCleaning() { //申请维修跳转
                this.$router.push({
                    name: 'contractChoose',
                    query: {
                        changeType:this.changeType//页面判断跳转我要维修页面
                    },
                })
            },
            //拨打电话
            showMobile(e,e2){
                let that = this;
				that.temporaryOwnerName = e2;
                that.temporaryOwnerMobile = e;
                that.dialogTitle='我的保洁',
                that.isMobileShow = !that.isMobileShow;
            },
            //关闭拨打电话弹窗
            hideDialog() {
                let that = this;
                that.isMobileShow = false;
            },
            cancelClick(id){
                this.afterRent_id=id//获取租后id
                this.userAfterRentCancel();
                this.onRefresh();
            },
            onRefresh() {//刷新
                setTimeout(() => {
                    this.cleanUserList=[];
                    this.cleanType=[];
                    this.cosbearMap=[];
                    this. bannerList=[];
                    this.isLoading= false,
                    this.querycleaningList();
                    this.getQueryBaseData()
                },800);
            },
            querycleaningList(){ //维修项目初始化接口
                let that = this;
                let querycleaningListData={
                    user_id:globaluserId(),
                    roomid:that.roomid
                }
                querycleaningList(querycleaningListData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code == 0){
                            if (response.data.data.cleanUserList.length==0){
                                that.noContent=true
                            }else{
                                that.cleanUserList=response.data.data.cleanUserList;//保洁历史列表
                            }
                            if(response.data.data.bannerList==undefined||response.data.data.bannerList==''){//没有图片时添加默认图片
                                that.bannerList=that.errorImg
                            }else{
                                that.bannerList=response.data.data.bannerList  //轮播列表
                                console.log(that.bannerList)
                            }
                          //轮播列表
                        }else if (response.data.code != 0) {
                            responseUtil.alertMsg(that,response.data.msg)
                        } else {
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })
            },
            getQueryBaseData(){//字典表接口
                let that =this;
                let _parms = {
                    fdName:that.fdName,
                    dbName:that.dbName,
                }
                getQueryBaseData(_parms).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                                that.cleanType=response.data.data.cleanType,
                               that.cosbearMap=response.data.data.COSBEARMAP
                        }else if (response.data.code != 0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else{
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })
            },
            userAfterRentCancel(){//用户租后取消申请接口
                let that =this;
                let _parms = {
                    user_id:globaluserId(),
                    afterRent_id:that.afterRent_id,
                    type:that.type,
                    cancelReason:that.cancelReason
                }
                userAfterRentCancel(_parms).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else if (response.data.code != 0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else{
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })
            },
            swiperJump(item) {
               //取出点击数据
                var list=item[0];
                console.log(list)
                if (!list){
                    return
                } else{
                    if (list.bannerJumpType==0){
                        //跳转页面
                        if (list.dictionaryName==("roomList"||"mine"||"workMine"||"workHome"||"room"||"workData")) {
                            this.numLogin(list.dictionaryName)
                        }
                    }else if(list.bannerJumpType==1){
                        this.jumprichText(list.richText)
                    }else {
                        return;
                    }
                }
            },

            numLogin(name) {
                if (checkAndroid()) {
                    codeJump();
                } else if (checkIOS()) {
                    console.log("对ios传参");
                    window.webkit.messageHandlers.codeJump.postMessage({name});
                }
            },
            //跳转原生页面
            jumprichPage(bannerJump){
                this.$router.push({
                    name : bannerJump,
                    query : {
                        textType : bannerJump,
                        type : '2',
                    }
                })
            },

            //跳转富文本页面
            jumprichText(type){
                this.$router.push({
                    name : 'userService',
                    query : {
                        textType : type,
                        type : '1',
                    }
                })
            },

        },

    }
</script>

<style scoped>
    .myCleaning {
        padding-bottom: 100px;
    }

    .button{
        color: #EB7B65;
        border-radius: 8px;
        width: 80px;
        border: 1px solid transparent;
        background-image: linear-gradient(white,white),linear-gradient(to right,#ffb96f,#ff6d44);
        background-clip: padding-box,border-box;
        background-origin: border-box;
        margin: 15px 0px 17px 250px;
        height: 30px;
        font-size: 12px;
    }
    .single_img{
        padding: 19px 0px 0px 140px;
        width: 19.96px;
        height: 19.96px;
    }
    .single_aunt{
        padding: 21px 0px 0px 10px;
        font-weight: bold;
        height: 17px;
        font-size: 13px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .single_phone{
        font-weight: normal;
        padding-left:3px;
    }
    .single_people{
        padding-top: 20px;
        padding-left: 14px;
        padding-bottom: 15px;
        width: 15.51px;
        height: 19.37px;

    }
    .single{
        display: flex;
        border-top:1px solid #EFEFEF ;
    }
    .cleaning{
        padding-left: 15px;
    }
    .clenaing_gray{
        width: 345px;
        height: 140px;
        border-radius: 8px;
        background-color: rgba(216, 216, 216, 1);
        box-shadow: 0px 6px 6px -4px rgba(129, 129, 129, 0.4);
    }
.grayDiv_image{
    width: 345px;
    height: 140px;
    border-radius: 8px;
    background-color: rgba(216, 216, 216, 1);
    box-shadow: 0px 6px 6px -4px rgba(129, 129, 129, 0.4);
}
    .clenaing_img{
        margin-top: 22px;
        width: 345px;
        height: 66px;
        border-radius: 8px;
        background-color: #DCF5FF;
        box-shadow: 0px 6px 6px -4px #D1EAFD;
    }
    .primaryClear{
        border-top: 1px solid #EFEFEF ;
    }
    .applicationClenaing{
        margin-top: -58px;
        margin-left: 22px;
        font-weight: bold;
        width: 60px;
        height: 21px;
        color: rgba(34, 34, 34, 1);
        font-size: 15px;
        font-family: PingFangSC-Semibold;
    }
    .applicationMessage{
        height: 13px;
        margin-left: 22px;
        color: rgba(153, 153, 153, 1);
        font-size: 9px;
        font-family: PingFangSC-Regular;
    }
    .applicationClenaing_case{
        width: 36px;
        height: 43.63px;
        margin-top: 38px;
    }
    .trashCanHide{
        display: none;
    }
    .myClenaing_text{
        margin-top: -28px;
        margin-left: 15px;
        font-weight: bold;
       width: 100%;
        height: 28px;
        color: rgba(34, 34, 34, 1);
        font-size: 20px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .complaint_message{
        margin-top: 13px;
        width: 345px;
        border-radius: 8px;
        background-color: #FFFFFF;
    }
    .complaint_message1{
        margin-top: 20px;
        width: 345px;
        border-radius: 8px;
        background-color: #FFFFFF;
    }
    .wait-approve{
        width: 46px;
        height: 14px;
        line-height: 15px;
        font-size: 12px;
        text-align: center;
        padding-top: 2px;
        color: white;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffb96f,#ff6d44);
    }
    .wait-approveA{
        width: 46px;
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        text-align: center;
        padding-top: 2px;
        color: white;
        border-radius: 8px 0;
        background: linear-gradient(to right,#66CB94,#28D3B0);
    }
    .wait-approveB{
        width: 46px;
        height: 14px;
        line-height: 15px;
        font-size: 12px;
        text-align: center;
        padding-top: 2px;
        color: white;
        border-radius: 8px 0;
        background: linear-gradient(to right,#999999,#999999)
    }
    .site{

    }
    .comlaint_site{
        display: flex;
        padding: 15px 0px 10px 15px;
        font-size: 15px;
        /*width: 315px;*/
        border-bottom: 1px solid #EFEFEF ;
        font-weight: bold;
    }
    .houseInfo{
        width:345px;
        padding: 15px 15px;
        /*border-bottom: 1px solid #EFEFEF ;*/
    }

    .boldFont{
        font-size: 14px;
        font-weight: bold;
        height: 24px;
    }
    .normalFont{
        font-size: 13px;
        font-weight: normal;
        color: #999;
    }

</style>